/* -------------------------------------------------------------------------- */
/*                     This file contains the action types                    */
/* -------------------------------------------------------------------------- */

//Auth action types
export const SIGN_IN = "SIGN_IN"
export const SIGN_OUT = "SIGN_OUT"
export const GET_PRIVILEGE = "GET_PRIVILEGE"

//App bar (side nav) actions types
export const TOGGLE_LOGOUT_MODAL = "TOGGLE_LOGOUT_MODAL"
export const TOGGLE_DRAWER = "TOGGLE_DRAWER"
export const ACTIVE_PATH_CHANGED = "ACTIVE_PATH_CHANGED"

// Submit user actions
export const SUBMIT_USER_DATA = "SUBMIT_USER_DATA"

//Organizations Action types
export const GET_ORGANIZATION_SINGLE = "GET_ORGANIZATION_SINGLE"
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS"
export const REGISTER_ORGANIZATION = "REGISTER_ORGANIZATION"
export const UPDATE_ACCOUNT_PRIVILEGE = "UPDATE_ACCOUNT_PRIVILEGE"
export const GET_SESSIONS_BY_ORGANIZATION = "GET_SESSIONS_BY_ORGANIZATION"
export const UPDATE_SELECTED_ORGANIZATION = "UPDATE_SELECTED_ORGANIZATION"
export const GET_DETAILS_CURRENT_AUTH_ORG = "GET_DETAILS_CURRENT_AUTH_ORG"

// Game Level Action types
export const GET_LEVELS_REQUEST = "GET_LEVELS_REQUEST"
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS"
export const GET_LEVELS_FAILURE = "GET_LEVELS_FAILURE"
export const GET_LEVEL_REQUEST = "GET_LEVEL_REQUEST"
export const GET_LEVEL_SUCCESS = "GET_LEVEL_SUCCESS"
export const GET_LEVEL_FAILURE = "GET_LEVEL_FAILURE"
export const GET_LEVEL_CLEAR = "GET_LEVEL_CLEAR"

//User Action types
export const GET_USERS_BY_ORG = "GET_USERS_BY_ORG"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"
export const CREATE_USER = "CREATE_USER"
export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER"
export const TOGGLE_LOGIN_FOR_USERS = "TOGGLE_LOGIN_FOR_USERS"
export const ARCHIVE_USER = "ARCHIVE_USER"
export const GET_SELECTED_USER = "GET_SELECTED_USER"
export const GET_USER = "GET_USER"
export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILURE = "GET_USER_FAILURE"
export const SET_LANGUAGE = "SET_LANGUAGE"

export const GET_USER_ACTIVITY_STATS_REQUEST = 'GET_USER_ACTIVITY_STATS_REQUEST'
export const GET_USER_ACTIVITY_STATS_SUCCESS = 'GET_USER_ACTIVITY_STATS_SUCCESS'
export const GET_USER_ACTIVITY_STATS_FAILURE = 'GET_USER_ACTIVITY_STATS_FAILURE'

export const GET_PATIENT_REPORT_CONFIG_REQUEST =
  "GET_PATIENT_REPORT_CONFIG_REQUEST"
export const GET_PATIENT_REPORT_CONFIG_SUCCESS =
  "GET_PATIENT_REPORT_CONFIG_SUCCESS"
export const GET_PATIENT_REPORT_CONFIG_FAILURE =
  "GET_PATIENT_REPORT_CONFIG_FAILURE"
export const GET_PATIENT_REPORT_CONFIG_CLEAR = "GET_PATIENT_REPORT_CONFIG_CLEAR"
export const CREATE_PATIENT_REPORT_CONFIG_REQUEST =
  "CREATE_PATIENT_REPORT_CONFIG_REQUEST"
export const CREATE_PATIENT_REPORT_CONFIG_SUCCESS =
  "CREATE_PATIENT_REPORT_CONFIG_SUCCESS"
export const CREATE_PATIENT_REPORT_CONFIG_FAILURE =
  "CREATE_PATIENT_REPORT_CONFIG_FAILURE"
export const CREATE_PATIENT_REPORT_CONFIG_CLEAR = "CREATE_PATIENT_REPORT_CONFIG_CLEAR"

//Modal Action types
export const SHOW_MODAL = "SHOW_MODAL"
export const HIDE_MODAL = "HIDE_MODAL"

//General Graph Action types (User Cognitive Profile)
export const UPDATE_START_DATE_SELECT = "UPDATE_START_DATE_SELECT"
export const UPDATE_END_DATE_SELECT = "UPDATE_END_DATE_SELECT"
export const TOGGLE_MEMORY_LINE = "TOGGLE_MEMORY_LINE"
export const TOGGLE_PROCESSING_LINE = "TOGGLE_PROCESSING_LINE"
export const TOGGLE_ATTENTION_LINE = "TOGGLE_ATTENTION_LINE"
export const GET_GRAPH_DATA = "GET_GRAPH_DATA"

export const GET_ACTIVE_USERS = "GET_ACTIVE_USERS"

//User Radar Plot Action types
export const GET_RADAR_PLOT_DATA = "GET_RADAR_PLOT_DATA"
export const UPDATE_WITHIN_DATE_SELECT = "UPDATE_WITHIN_DATE_SELECT"

// User Correct vs Incorrect
export const GET_USER_SESSIONS = "GET_USER_SESSIONS"

// User Skill Level Plays Over Time
export const GET_USER_SKILL_LEVELS = "GET_USER_SKILL_LEVELS"
//Bar Chart
export const GET_STACKED_BAR_CHART = "GET_STACKED_BAR_CHART"
//Schedule Sessions
export const CREATE_SESSION = "CREATE_SESSION"
export const SHOW_SESSION = "SHOW_SESSION"
export const ADD_PATIENT = "ADD_PATIENT"

// Permissions and roles
export const PERMADMIN_GET_PERMISSIONS_LISTING =
  "PERMADMIN_GET_PERMISSIONS_LISTING"
export const PERMADMIN_CREATE_PERMISSION = "PERMADMIN_CREATE_PERMISSION"
export const PERMADMIN_DELETE_PERMISSION = "PERMADMIN_DELETE_PERMISSION"
export const PERMADMIN_GET_ROLES_LISTING = "PERMADMIN_GET_ROLES_LISTING"
export const PERMADMIN_CREATE_ROLE = "PERMADMIN_CREATE_ROLE"
export const PERMADMIN_UPDATE_ROLE = "PERMADMIN_UPDATE_ROLE"
export const PERMADMIN_UPDATE_PERMISSION = "PERMADMIN_UPDATE_PERMISSION"

// Patient Notes
export const GET_PATIENT_NOTES = "GET_PATIENT_NOTES"
export const UPDATE_PATIENT_NOTE = "UPDATE_PATIENT_NOTE"
export const CREATE_PATIENT_NOTE = "CREATE_PATIENT_NOTE"
export const DELETE_PATIENT_NOTE = "DELETE_PATIENT_NOTE"

// MetaTemplates
export const GET_META_TEMPLATES = "GET_META_TEMPLATES"
export const GET_META_TEMPLATE_SESSIONS = "GET_META_TEMPLATE_SESSIONS"
export const GET_META_DATA_SESSIONS = "GET_META_DATA_SESSIONS"

//Sessions
export const CREATE_SCHEULE_SESSION = "CREATE_SCHEULE_SESSION"
export const MESSAGE_SCHEULE_SESSION_FAILER = "MESSAGE_SCHEULE_SESSION_FAILER"
export const FETCH_SCHEULE_SESSION = "FETCH_SCHEULE_SESSION"
export const EDIT_SCHEULE_SESSION = "EDIT_SCHEULE_SESSION"
export const DELETE_SCHEULE_SESSION = "DELETE_SCHEULE_SESSION"
export const RUCURRING_SCHEULE_SESSION = "RUCURRING_SCHEULE_SESSION"
export const COUNT_SESSION_FOR_ORG = "COUNT_SESSION_FOR_ORG"
export const GET_OVER_PROGRESS_OVER_TIME = "GET_OVER_PROGRESS_OVER_TIME"
export const GET_ASSIGEND_PATIENT_SESSIONS_PARCTITIONER =
  "GET_ASSIGEND_PATIENT_SESSIONS_PARCTITIONER"

//count patients
export const GET_COUNT_PAITENTS_ORG = "GET_COUNT_PAITENTS_ORG"
export const GET_RATINGS_PAITENTS_ORG = "GET_RATINGS_PAITENTS_ORG"
export const GET_PATIENTS_SESSIONS_ORG = "GET_PATIENTS_SESSIONS_ORG"
export const GET_USERS_BY_ORG_ID = "GET_USERS_BY_ORG_ID"
export const GET_LONGEST_STREAK = "GET_LONGEST_STREAK"
export const GET_ASSIGNED_PATIENTS = "GET_ASSIGNED_PATIENTS"

// Navbar
export const NAV_EXPAND = "NAV_EXPAND"
export const NAV_COLLAPSE = "NAV_COLLAPSE"

// Assignments
export const GET_ASSIGNMENTS = "GET_ASSIGNMENTS"
export const CREATE_ASSIGNMENT_REQUEST = "CREATE_ASSIGNMENT_REQUEST"
export const CREATE_ASSIGNMENT_SUCCESS = "CREATE_ASSIGNMENT_SUCCESS"
export const CREATE_ASSIGNMENT_FAILURE = "CREATE_ASSIGNMENT_FAILURE"
export const CREATE_ASSIGNMENT_RESET = "CREATE_ASSIGNMENT_RESET"
export const UPDATE_ACTIVITY_REQUEST = "UPDATE_ACTIVITY_REQUEST"
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS"
export const UPDATE_ACTIVITY_FAILURE = "UPDATE_ACTIVITY_FAILURE"
export const UPDATE_ACTIVITY_RESET = "UPDATE_ACTIVITY_RESET"
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
