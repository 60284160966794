import React, { useState, useEffect } from "react"
import moment from "moment"
import { useSelector, useDispatch } from "react-redux"
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useForm, Controller } from "react-hook-form"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { useSnackbar } from "notistack"

import ScrollToTop from "../sharedComponents/ScrollToTop"
import NFButton from "../sharedComponents/NFButton"
import ActivityInput from "./ActivityInput"
import {
  getLevel,
  updateActivity,
  updateActivityReset,
  deleteActivity,
} from "../../../actions/index"
import TitleRow from "../sharedComponents/TitleRow"

const TOPLEVEL_ACTIVITY_PARAMS = [
  "completion_condition_mode",
  "timer",
  "is_adaptive_difficulty",
  "difficulty",
  "completion_clears",
  "completion_duration",
]

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(6),
    borderRadius: "0.25rem",
    boxShadow: "none",
    border: `1px solid ${theme.palette.blue[200]}`,
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  slider: {
    width: "100%",
  },
  buttonPrimary: {
    marginTop: theme.spacing(2),
  },
}))

const AssignmentEdit = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let { id } = useParams()
  const loadedActivity = useLocation().state?.activity
  const { enqueueSnackbar } = useSnackbar()

  const { levels, hasFetchedLevels } = useSelector(
    (state) => state.gameLevelReducer
  )
  const { updating, updateSuccess, updateError } = useSelector(
    (state) => state.assignmentReducer
  )

  useEffect(() => {
    if (!hasFetchedLevels) {
      dispatch(getLevel(loadedActivity.game_level.id))
    }
  }, [dispatch, hasFetchedLevels, loadedActivity])

  // @todo handle direct navigation case with no levels loaded, race condition
  const level = levels.find((l) => l.id === loadedActivity.game_level.id)
  const [activity, setActivity] = useState({ ...loadedActivity })
  // const [isClearsCompletion, setIsClearsCompletion] = useState(
  //   loadedActivity.activity_parameters.completion_condition_mode === "CLEARS"
  // )
  // const [isAdaptiveDifficulty, setIsAdaptiveDifficulty] = useState(
  //   loadedActivity.activity_parameters.is_adaptive_difficulty
  // )
  const classes = useStyles()

  const { control, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      ...loadedActivity.activity_parameters,
      ...loadedActivity.gameplay_parameters,
    },
  })

  useEffect(() => {
    if (updateSuccess) {
      dispatch(updateActivityReset())
      enqueueSnackbar(
        `${activity.game_level.name} ${moment(activity.start).format(
          "MMM D"
        )} updated`,
        {
          variant: "success",
          persist: false,
        }
      )
      history.push(`/user-profile/${id}`)
    }
  }, [dispatch, updateSuccess, history, id, enqueueSnackbar, activity.game_level.name, activity.start])

  const isClearsCompletion = watch("completion_condition_mode") === "CLEARS"
  const isAdaptiveDifficulty = watch("is_adaptive_difficulty")

  const onSubmit = (data) => {
    // separate flattened form data back into activity and gameplay params
    const activityParams = Object.entries(data).reduce((acc, [key, value]) => {
      if (Object.keys(level.activity_parameters).includes(key)) {
        acc[key] = value
      }
      return acc
    }, {})
    const gameplayParams = Object.entries(data).reduce((acc, [key, value]) => {
      if (Object.keys(level.gameplay_parameters).includes(key)) {
        acc[key] = value
      }
      return acc
    }, {})
    activityParams.is_edited = true
    dispatch(
      updateActivity(activity.id, {
        activity_parameters: activityParams,
        gameplay_parameters: gameplayParams,
      })
    )
  }

  const nonTopLevelActivityParams =
    level.activity_parameters &&
    Object.fromEntries(
      Object.entries(level.activity_parameters).filter(
        ([key]) => !TOPLEVEL_ACTIVITY_PARAMS.includes(key)
      )
    )

  const handleGameParamChange = (key, value) => {
    setActivity({
      ...activity,
      gameplay_parameters: { ...activity.gameplay_parameters, [key]: value },
    })
  }

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this assignment?")) {
      dispatch(deleteActivity(activity.id))
      history.push(`/user-profile/${id}`)
    }
  }

  if (!activity || !level.name) {
    return <Typography variant='h2'>Loading Activity...</Typography>
  }

  const pageTitle = `Edit Activity: ${activity.game_level.name} ${moment(
    activity.start
  ).format("M/D")}`

  return (
    <>
      <ScrollToTop />
      <Grid container direction='column'>
        <TitleRow title={pageTitle} backDestination={`/user-profile/${id}`} />
        <Grid item>
          <Paper className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction='column' spacing={3}>
                <Typography variant='h6'>Completion Settings</Typography>
                <Grid item md={6} xs={12}>
                  <FormControl
                    component='fieldset'
                    className={classes.formGroup}>
                    <FormLabel component='legend'>Completion Mode</FormLabel>
                    <Controller
                      name='completion_condition_mode'
                      control={control}
                      error={!!errors.completion_condition_mode}
                      render={({ name, value, onChange, ref }) => (
                        <RadioGroup
                          aria-label='Completion Condition Mode'
                          name={name}
                          value={value}
                          ref={ref}
                          onChange={(e) => onChange(e.target.value)}>
                          {level.activity_parameters.completion_condition_mode.values.map(
                            (mode) => (
                              <FormControlLabel
                                key={mode}
                                value={mode}
                                control={<Radio ref={ref} />}
                                label={mode}
                              />
                            )
                          )}
                        </RadioGroup>
                      )}
                    />
                    {errors.completion_condition_mode && (
                      <Typography color='error'>
                        {errors.completion_condition_mode.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {isClearsCompletion && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      as={ActivityInput}
                      name='completion_clears'
                      control={control}
                      parameter={level.activity_parameters.completion_clears}
                      error={errors.completion_clears}
                    />
                  </Grid>
                )}

                {!isClearsCompletion && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      as={ActivityInput}
                      name='completion_duration'
                      control={control}
                      parameter={level.activity_parameters.completion_duration}
                      error={errors.completion_duration}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <Controller
                    as={ActivityInput}
                    name='timer'
                    control={control}
                    parameter={level.activity_parameters.timer}
                    error={errors.timer}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl
                    component='fieldset'
                    className={classes.formGroup}>
                    <FormLabel component='legend'>
                      Adaptive Difficulty
                    </FormLabel>
                    <Controller
                      name='is_adaptive_difficulty'
                      control={control}
                      error={!!errors.is_adaptive_difficulty}
                      render={({ name, value, onChange, ref }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={value}
                              onChange={(_, newValue) => onChange(newValue)}
                              inputRef={ref}
                            />
                          }
                          label={value ? "Enabled" : "Disabled"}
                        />
                      )}
                    />
                    <Typography variant='caption'>
                      {
                        level.activity_parameters.is_adaptive_difficulty
                          .description
                      }
                    </Typography>
                  </FormControl>
                </Grid>

                {!isAdaptiveDifficulty && (
                  <Grid item xs={12}>
                    <FormControl
                      component='fieldset'
                      className={classes.formGroup}>
                      <FormLabel component='legend'>
                        Difficulty (1-10)
                      </FormLabel>
                      <Controller
                        name='difficulty'
                        control={control}
                        error={!!errors.difficulty}
                        render={({ name, value, onChange, ref }) => (
                          <Slider
                            value={value}
                            onChange={(_, newValue) => onChange(newValue)}
                            min={1}
                            max={10}
                            step={1}
                            marks
                            valueLabelDisplay='auto'
                            className={classes.slider}
                            ref={ref}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}

                {!isAdaptiveDifficulty && level.gameplay_parameters && (
                  <Typography variant='h6'>Difficulty Settings</Typography>
                )}
                {!isAdaptiveDifficulty &&
                  level.gameplay_parameters &&
                  Object.entries(level.gameplay_parameters)
                    .filter(([k, l]) => l.is_difficulty_dependent)
                    ?.map(([key, param]) => (
                      <Grid item xs={12} key={key}>
                        <ActivityInput
                          parameter={{ ...param, key: key }}
                          value={activity.gameplay_parameters[key]}
                          onChange={(v) => handleGameParamChange(key, v)}
                        />
                      </Grid>
                    ))}

                <Typography variant='h6'>Activity Settings</Typography>
                {Object.entries(nonTopLevelActivityParams)?.map(
                  ([key, param]) => (
                    <Grid item xs={12} key={key}>
                      <Controller
                        as={ActivityInput}
                        name={key}
                        control={control}
                        parameter={{ ...param, key: key }}
                        error={errors[key]}
                      />
                    </Grid>
                  )
                )}

                {Object.entries(level.gameplay_parameters).filter(
                  ([k, l]) => !l.is_difficulty_dependent
                ).length > 0 && (
                  <Typography variant='h6'>{level.name} Settings</Typography>
                )}
                {Object.entries(level.gameplay_parameters)
                  .filter(([k, l]) => !l.is_difficulty_dependent)
                  ?.map(([key, param]) => (
                    <Grid item xs={12} md={6} key={key}>
                      <Controller
                        as={ActivityInput}
                        name={key}
                        control={control}
                        parameter={{ ...param, key: key }}
                        error={errors[key]}
                      />
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <NFButton type='submit' variant='primary'>
                        {updating ? "Updating..." : "Update Assignment"}
                        {updating && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </NFButton>
                    </Grid>
                    <Grid item>
                      <NFButton 
                        variant='secondary' 
                        onClick={handleDelete}
                        style={{ backgroundColor: '#f44336', color: 'white' }}
                      >
                        Delete Assignment
                      </NFButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default AssignmentEdit
